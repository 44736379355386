@import "../functions/strip-unit";

// https://css-tricks.com/simplified-fluid-typography/
// `fluid-calc` returns a `calc` property that aids in providing vw-based fluid typography
@function fluid-calc(
  $min-value,
  $max-value,
  $min-breakpoint: 320px,
  $max-breakpoint: 360px
) {
  @return calc(
    #{$min-value} + #{strip-unit($max-value - $min-value)} *
      (100vw - #{$min-breakpoint}) / #{strip-unit(
        $max-breakpoint - $min-breakpoint
      )}
  );
}

// `fluid` takes in a sass-map of declarations, each of which should include a sass-map containing a minimum px value and maximum px value.
// `fluid-calc` is called to generate a linear-scaling size
// all maximum values are output as an override for the max-screen-size, which can be overridden as the second paramater.
@mixin fluid($declarations, $min-breakpoint: 320px, $max-breakpoint: 360px) {
  $max-rules: ();

  @each $property, $value in $declarations {
    $min: nth($value, 1);
    $max: nth($value, 2);

    $max-rules: map-merge(
      $max-rules,
      (
        $property: $max,
      )
    );

    #{$property}: fluid-calc(
      $min,
      $max,
      $min-breakpoint: $min-breakpoint,
      $max-breakpoint: $max-breakpoint
    );
  }

  @media (min-width: $max-breakpoint) {
    @each $property, $value in $max-rules {
      #{$property}: $value;
    }
  }
}
