@import "../../styles/variables/colors";

.navToggleGroup {
  position: relative;
  height: 100%;
  box-sizing: border-box;
  background-color: $color--white;
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: space-evenly;
  margin-left: 0.75em;
  margin-right: 0.75em;
}

.roundedSelector {
  position: absolute;
  left: 0;
  height: calc(100% - 0.25em);
  width: 50%;
  background-color: $color--pink;
  border-radius: 1.5625em;
}
