@import "../../styles/variables/colors";

.modal {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1999;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  background-color: $color--white;
  color: $color--pink;
  text-align: center;
}

.innerContainer {
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
  margin-top: 10vh;
}

.logo {
  opacity: 0;
  width: 25vw;
  margin-bottom: 4vh;
}

.introCopy {
  opacity: 0;
  font-family: TeleNeoWeb-Thin;
  color: $color--pink;
  font-size: 2em;
  margin-left: 10vw;
  margin-right: 10vw;
}
