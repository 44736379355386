@import "../../styles/variables/colors";

.modalRoot {
  position: absolute;
  z-index: 1899;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  background-color: white;
  color: $color--pink;
  display:grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr auto 1fr;
  grid-template-areas: 'top' 'middle' 'bottom';
  overflow: hidden;
  padding: 10%;
  box-sizing: border-box;
}

.modalRoot > .topSection {
  grid-area: top;
  //background-color: purple;
  display:flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-top: 10%;
  margin-bottom: 15%;
}

.modalRoot > .middleSection {
  grid-area: middle;
  //background-color: gray;
  text-align: center;
}

.modalRoot > .bottomSection {
  grid-area: bottom;
  //background-color: purple;
}

.message {
  font-family: TeleNeoWeb-Thin;
  font-size: 1.8em;
  text-align: center;
  text-transform: uppercase;
}

.logo {
  width: 20vw;
}