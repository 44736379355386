@import "../../styles/variables/colors";

.landscapeWarning {
  position: absolute;
  z-index: 9999;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  background-color: white;
  color: $color--pink;
  font-family: TeleNeoWeb-Thin;
  font-size: 2em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (orientation: portrait) {
    display: none;
  }

  @media (orientation: landscape) {
    display: flex;
  }

  .content {
    text-align: center;
    padding: 0px 25%;
  }
}
