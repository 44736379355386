@import "../../styles/variables/colors";

.navIconButton {
  cursor: pointer;
  padding: 0.5em;
  background-color: $color--white;
  color: $color--black;
  background-color: transparent;
  border: none;
  box-sizing: unset;
}
