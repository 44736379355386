@import "../../styles/variables/colors";
@import "../../styles/mixins/fluid";

.outroModal {
  position: absolute;
  opacity: 0;
  visibility: none;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1999;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  //min-height: calc(var(--vh, 1vh) * 100);
  height: calc(var(--vh, 1vh) * 100);
  background-color: white;
  text-align: center;
  padding: 10%;
  box-sizing: border-box;
}

.outroModal .logo {
  width: 20vw;
  // width: 30vw;
  //margin-bottom: 5%;
}

.outroModal .thankYouCopy {
  font-family: TeleNeoWeb-Thin;
  font-size: 1.8em;
  color: $color--pink;
  margin: .5em 0;
  text-transform: uppercase;
  margin-bottom: calc(var(--vh,1vh) * 10);
}

.outroModal .surveyCta {
  font-family: TeleNeoWeb-Medium;
  font-size: 1.1em;
  color: $color--pink;
  margin: 0.75em 1em;
}

.outroModal .shareCopy {
  font-family: TeleNeoWeb-Thin;
  font-size: 1.8em;
  color: $color--pink;
  margin-top: 1.2em;
  margin-bottom: .4em;
  text-transform: uppercase;
}

.outroModal .surveyLink {
  color: black;
  text-transform: uppercase;
  font-family: TeleNeoWeb-Medium;
  font-size: 1em;
}

.outroModal .socialLinks {
  margin-bottom: 1em;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.outroModal .socialLink {
  margin: 0px 1em;
  width: 30px;
  height: 30px;
  border: none;
  pointer-events: none;
  background-color: transparent;
}

.outroModal .button {
  font-family: TeleNeoWeb-ExtraBold;
  // @include fluid(
  //   (
  //     font-size: (
  //       1em,
  //       1em,
  //     ),
  //   )
  // );
  color: $color--white;
  cursor: pointer;
  padding: 0.75em 1em;
  text-transform: uppercase;
  background-color: $color--white;
  border: 3px solid $color--pink;
  border-radius: 2.375em;
  color: black;
  font-size: 1.2em;
  white-space: nowrap;
}