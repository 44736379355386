@import "../../styles/variables/colors";

.landingViewRoot {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.landingViewContainer {
  height: 100%;
  width: 100%;
  padding: 5%;
  box-sizing: border-box;
}

.landingViewRoot h1 {
  text-align: center;
  font-family: TeleNeoWeb-Bold;
  font-size: 2em;
  color: $color--pink;
  text-transform: uppercase;
}

.topicButton {
  width: 100%;
  cursor: pointer;
  margin: 0.5em 0em;
  padding: 0.75em 1em;
  background-color: $color--white;
  border: 3px solid $color--pink;
  border-radius: 2.375em;
  color: $color--black;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: TeleNeoWeb-ExtraBold;
  font-size: 1.5em;
  transition: color background-color 0.2s ease;
}