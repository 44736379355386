@import "../../styles/variables/colors";

.topicButton {
  opacity: 0;
  width: 100%;
  cursor: pointer;
  margin-bottom: 0.75em;
  padding: 0.75em 1em;
  background-color: $color--white;
  border: 3px solid $color--pink;
  border-radius: 2.375em;
  font-family: TeleNeoWeb-ExtraBold;
  font-size: 1.5em;
  transition: color background-color 0.2s ease;

  &.viewed {
    color: $color--black;
    border-color: $color--black;
    background-color: $color--white;
  }

  &:active {
    color: $color--white;
    background-color: $color--pink;
  }
}
