@import "../../styles/variables/colors";

.fiveGViewRoot {
  height: 100%;
  //background-color: orange;
  display:grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr;
  grid-template-areas: 'header' 'content';
  overflow: hidden;
}

.fiveGViewRoot >.headerSection {
  grid-area: header;
  //box-sizing: border-box;
  //background-color: lightgray;
}

.fiveGViewRoot > .contentSection {
  grid-area: content;
  //box-sizing: border-box;
  //background-color: purple;
}