@import "../../styles/variables/colors";

.appRoot {
  width: 100vw;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  overflow: hidden;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr auto;
  grid-template-areas: 'content' 'footer';

  // @media (orientation: portrait) { display: grid;  }
  // @media (orientation: landscape) { display: none; }
}
.appRoot > .contentSection {
  grid-area: content;
  box-sizing: border-box;
  //background-color: purple;
}

.appRoot >.footerSection {
  grid-area: footer;
  box-sizing: border-box;
  //background-color: lightgray;
}