@import "../../styles/variables/colors";

.coachMarksContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding-top: 5vh;
}

.coachMarks {
  width: 45vw;
}
