@import "../../styles/variables/colors";

.draggableMap {
  position: relative;
  margin: 0;
  overflow: hidden;
}

.mapContainer {
  padding: 3px 0px;
}

.mapBackground {
  width: 100%;
  height: auto;
  border: 0;
  vertical-align: bottom;
}

.mapOverlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
}

.mapGradient {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100% - 6px);
  margin: 3px 0px;
  box-sizing: border-box;
  box-shadow: inset 0px 12px 12px -12px #333, inset 0px -12px 12px -12px #333;
}

.pinkBorder {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 15.820313%;
  box-shadow: inset 0px 0px 0px 3px $color--pink;
}

.arrows {
    width: 172%;
    margin-left: -36%;
    margin-top: 79%;
}