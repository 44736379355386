@import "../../styles/variables/colors";
@import "../../styles/mixins/fluid";

.navToggleButton {
  cursor: pointer;
  padding: 1em 0em;
  color: $color--black;
  background-color: transparent;
  border: none;
  z-index: 10;
  flex-basis: 100%;
  transition: color 0.2s ease 0.2s;
  font-family: TeleNeoWeb-ExtraBold;
  @include fluid(
    (
      font-size: (
        1em,
        1.125em,
      ),
    )
  );
  text-align: center;
}

.selected {
  color: $color--white;
}
