@import "../../styles/variables/colors";

.detailViewRoot {
  height: 100%;
  padding: 5%;
  box-sizing: border-box;
}

.detailViewRoot h1 {
  text-align: center;
  font-family: TeleNeoWeb-Bold;
  font-size: 2em;
  color: $color--pink;
  text-transform: uppercase;
}