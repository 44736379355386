@import "../../styles/variables/colors";

.homeRoot {
  height: 100%;  
  display:grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr;
  grid-template-areas: 'map' 'content';
  overflow: hidden;
  //background-color: gray;
}

.homeRoot > .mapSection {
  grid-area: map;
}

.homeRoot > .contentSection {
  grid-area: content;
  //background-color: gray;
}

.contentRoot {  
  height: 100%;
  display:grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr;
  grid-template-areas: 'instructions' 'topics';
  overflow: hidden;
  //background-color: purple;
}

.contentRoot > .instructionSection {
  grid-area: instructions;
  text-align: center;
}

.contentRoot > .topicSection {
  grid-area: topics;
  position: relative;  
  overflow-x: hidden;
  overflow-y: scroll;
  box-sizing: border-box; 
  margin-left: 4%;
  margin-right: 4%; 
  //background-color: green;
}

.topicList {
  position: absolute;
  width:100%;
  //background-color: orange;
}

.instructionsHeadline {
  color: $color--pink;
  font-family: TeleNeoWeb-ExtraBold;
  font-size: 1.28em;
  margin-top: 0.6em;
  margin-bottom: 0.2em;
}

.instructions {
  color:black;
  font-family: TeleNeoWeb-Medium;
  margin-bottom: 1.3em;
}
