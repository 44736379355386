@import "../../styles/variables/colors";

.footerRoot {  
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color:white;
  padding-top: 5%;
  gap:5%;
}

.backButton {
  min-width: 120px;
  height: 69px;
  padding: 0.5em 1em;
}

.backIcon {
  height:35px;
}

.disconnectButton {
  height: 69px;
  color: $color--pink;
  min-width:180px;
}

