body {
  margin: 0;
  font-family: "Segoe UI", "Roboto", "Oxygen", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: "TeleNeoWeb-ExtraBold";
  src: local("TeleNeoWeb-ExtraBold"),
    url(./fonts/TeleNeoWeb-ExtraBold.woff2) format("woff2"),
    url(./fonts/TeleNeoWeb-ExtraBold.woff) format("woff");
  font-style: normal;
}

@font-face {
  font-family: "TeleNeoWeb-Medium";
  src: local("TeleNeoWeb-Medium"),
    url(./fonts/TeleNeoWeb-Medium.woff2) format("woff2"),
    url(./fonts/TeleNeoWeb-Medium.woff) format("woff");
  font-style: normal;
}

@font-face {
  font-family: "TeleNeoWeb-Thin";
  src: local("TeleNeoWeb-Thin"),
    url(./fonts/TeleNeoWeb-Thin.woff2) format("woff2"),
    url(./fonts/TeleNeoWeb-Thin.woff) format("woff");
  font-style: normal;
}

@font-face {
  font-family: "TeleNeoWeb-Bold";
  src: local("TeleNeoWeb-Bold"),
    url(./fonts/TeleNeoWeb-Bold.woff2) format("woff2"),
    url(./fonts/TeleNeoWeb-Bold.woff) format("woff");
  font-style: normal;
}
